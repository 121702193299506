import React, { useState, useEffect } from 'react';
import { LuHeart } from 'react-icons/lu';
import { FaHeart } from 'react-icons/fa';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import WhatsappContact from '../components/contact';
import LoginSignupModal from '../components/LoginSignupModal';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar3 from '../components/navbarbrown';
import { MdKeyboardArrowLeft } from "react-icons/md";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

const Spinner = () => (
  <div className="w-10 h-10 border-4 border-orange-500 border-t-transparent rounded-full animate-spin"></div>
);

const ProductDetail = () => {
  const navigate = useNavigate();
  const [product, setProduct] = useState(null);
  const [products, setProducts] = useState([]); // To store the list of products
  const [selectedSize, setSelectedSize] = useState('S'); // Default selected size
  const [randomProducts, setRandomProducts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { slug } = useParams(); // Assumes you have slug-based routing
  const location = useLocation(); // Use location to get query params
  const [userCountry, setUserCountry] = useState('US'); // Default to US
const [sizeDescription, setSizeDescription] = useState({});
  const [showDetails, setShowDetails] = useState(false);
  const [showSizeDescription, setShowSizeDescription] = useState(false);
  const [favorites, setFavorites] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
 // State to track the current non-feature image index
// State to track the current image
const [currentIndex, setCurrentIndex] = useState(0);

// Navigation Handlers
const handleNextImage = () => {
  setCurrentIndex((prevIndex) => (prevIndex + 1) % orderedImages.length);
};

const handlePrevImage = () => {
  setCurrentIndex((prevIndex) =>
    prevIndex === 0 ? orderedImages.length - 1 : prevIndex - 1
  );
};

  const refreshAccessToken = async () => {
    try {
      const refreshToken = localStorage.getItem('refresh_token');
      if (!refreshToken) {
        console.warn('No refresh token found');
        setIsLoggedIn(false); // Update login state
        return null; // Return null but don't redirect
      }
  
      const response = await fetch('https://saarconcept.net/api/token/refresh/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ refresh: refreshToken }),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        console.error('Server error:', errorData);
        setIsLoggedIn(false);
        return null; // Return null but don't redirect
      }
  
      const data = await response.json();
      localStorage.setItem('access_token', data.access);
      setIsLoggedIn(true);
      return data.access;
    } catch (error) {
      console.error('Error refreshing token:', error.message);
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      setIsLoggedIn(false);
      return null; // Return null but don't redirect
    }
  };
  
  useEffect(() => {
  if (!sizeDescription) {
    setSizeDescription({});
  }
}, [sizeDescription]);

  // Also modify the fetchProducts function to handle unauthenticated requests
  const fetchProducts = async () => {
    try {
      let token = localStorage.getItem('access_token');
      if (!token) token = await refreshAccessToken();
  
      // Make the request without authentication if no token is available
      const response = await axios.get('https://saarconcept.net/api/products-with-filters/', 
        token ? { headers: { Authorization: `Bearer ${token}` } } : {}
      );
  
      const fetchedProducts = response.data.products;
  
      // Initialize favorite status
      const initialFavoriteStatus = {};
      fetchedProducts.forEach(product => {
        product.product_colors.forEach(colorOption => {
          const key = `${product.id}-${colorOption.color.name}`;
          initialFavoriteStatus[key] = colorOption.is_favorite;
        });
      });
  
      setProducts(fetchedProducts);
      setFavoriteStatus(initialFavoriteStatus);
      setRandomProducts(fetchedProducts
        .filter(p => p.slug !== slug)
        .sort(() => 0.5 - Math.random())
        .slice(0, 4)
      );
  
    } catch (error) {
      console.error('Error fetching product list:', error);
      // Handle error without redirecting
      if (error.response?.status === 401) {
        const newToken = await refreshAccessToken();
        if (newToken) fetchProducts();
      }
    }
  };

  const handleActionClick = async (actionType, product, colorDetails) => {
    try {
      const token = localStorage.getItem('access_token');
      if (!token) {
        setIsModalOpen(true);
        return;
      }
  
      const favoriteKey = `${product.id}-${colorDetails.color.name}`;
      if (actionType === 'addToWishlist' && favoriteStatus[favoriteKey]) {
        toast.info('Product is already in your favorites!');
        return;
      }
  
     
      const apiUrl = actionType === 'addToWishlist'
        ? `https://saarconcept.net/api/favorites/`
        : `https://saarconcept.net/api/favorites/?product_color_id=${colorDetails.id}`;
  
      const requestOptions = {
        method: actionType === 'addToWishlist' ? 'POST' : 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };
  
      // Only include body for POST requests
      if (actionType === 'addToWishlist') {
        requestOptions.body = JSON.stringify({
          product_color_id: colorDetails.id,
        });
      }
  
      const response = await fetch(apiUrl, requestOptions);
  
      if (response.ok) {
        // Update local state
        setFavoriteStatus((prev) => ({
          ...prev,
          [favoriteKey]: actionType === 'addToWishlist',
        }));
  
        // Optionally refetch products
        fetchProducts();
      } else {
        const errorResponse = await response.json();
        console.error('API Error Response:', errorResponse);
        throw new Error('Failed to update favorites');
      }
    } catch (error) {
      console.error('Error handling action:', error);
    }
  };
  
  
  useEffect(() => {
    fetchProducts();
  }, []);
  

 
  // Add an effect to refresh favorites when the component mounts or when the token changes
  useEffect(() => {
    const token = localStorage.getItem('access_token');
    if (token) {
      fetchProducts();
    }
  }, [slug]);


useEffect(() => {
  const fetchProductDescription = async () => {
    if (slug) {
      try {
        const response = await axios.get(`https://saarconcept.net/api/products/${slug}/`);
        setProduct(response.data);  // Store the full product details
        setSizeDescription(response.data.size_description);  // Fetch the description based on slug
      } catch (error) {
        console.error('Error fetching product details:', error);
      }
    }
  };

  fetchProductDescription();
}, [slug]);


const handleSizeDetailsClick = () => {
  setShowSizeDescription(!showSizeDescription); // Toggle visibility

};
const handleBackClick = () => {
  setShowSizeDescription(false); // Hide the table and show other elements
};
  // Function to get query parameters
  const getQueryParam = (param) => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get(param);
  };

  const selectedColor = getQueryParam('color'); // Get the color from URL


  const [favoriteStatus, setFavoriteStatus] = useState({});

// Add this useEffect to update favorite status when products change
useEffect(() => {
  if (products && selectedColor) {
    const favoriteStatuses = {};
    products.forEach((product) => {
      product.product_colors.forEach((color) => {
        if (color.color.name === selectedColor) {
          favoriteStatuses[`${product.id}-${color.color.name}`] = color.is_favorite;
        }
      });
    });
    setFavoriteStatus(favoriteStatuses);
  }
}, [products, selectedColor]);



// Fetching product details based on slug
useEffect(() => {
  const fetchProduct = async () => {
    if (slug) {
      try {
        const response = await axios.get(`https://saarconcept.net/api/products/${slug}/`);
        setProduct(response.data);
      } catch (error) {
        console.error("Error fetching product details:", error);
      }
    }
  };

  fetchProduct();
}, [slug]);


  const handleNavigate = (url) => {
    navigate(url);
    setTimeout(() => {
      window.location.reload();
    }, 0);
  };

  const handleNavigate2 = (url) => {
    // Navigate to the desired URL
    navigate(url);
  
    // Scroll to top after navigation has completed
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);  // Set a small timeout to allow the route change to complete first
  };
  

  const handleSizeChange = (size) => {

    setSelectedSize(size); // Set the size to the state
  };
  
  const getLocationBasedPrice = (colorDetails) => {
    if (!colorDetails) return null;
  
    const countryPrice = colorDetails.country_prices?.find(
      (price) => price.country === userCountry
    );
  
    if (countryPrice) {
      if (!countryPrice.price_amount || !countryPrice.price_currency) {
        console.warn(
          `Incomplete price data for country ${userCountry}. Falling back to base price.`
        );
        return {
          amount: colorDetails.base_price,
          currency: 'USD',
        };
      }
  
      return {
        amount: countryPrice.price_amount,
        currency: countryPrice.price_currency,
      };
    }
  
    // Log fallback usage for debugging
    console.warn(
      `No specific price found for country ${userCountry}. Falling back to base price.`
    );
  
    // Fallback to base price in USD
    return {
      amount: colorDetails.base_price,
      currency: 'USD',
    };
  };
  
  const formatCurrency = (price, currency) => {
    if (!price) return '';
  
    const currencySymbols = {
      'USD': '$',
      'TRY': '₺',
      'IQD': 'IQD',
    };
  
    return `${currencySymbols[currency] || currency || 'USD'} ${parseFloat(price).toLocaleString()}`;
  };

  useEffect(() => {
  
    products.forEach((product) => {
      product.product_colors.forEach((color) => {
        if (color.color.name === selectedColor) {
          console.log(`For color ${selectedColor}, is_favorite: ${color.is_favorite}`);
        }
      });
    });
  }, [selectedColor, products]);

  // Detect user's country (you might want to use a geolocation service or IP detection)
  useEffect(() => {
    const detectUserCountry = async () => {
      try {
        // You can replace this with your preferred geolocation service
        const response = await fetch('https://ipapi.co/json/');
        const data = await response.json();
        // Map country codes to your supported countries
        const countryMap = {
          'TR': 'TR',
          'IQ': 'IQ'
        };
        setUserCountry(countryMap[data.country_code] || 'US');
      } catch (error) {
        console.error('Error detecting country:', error);
        setUserCountry('US'); // Fallback to US
      }
    };

    detectUserCountry();
  }, []);


  // Modified handle add to cart function
  const handleAddToCart = () => {
    if (!product || !selectedColor || !selectedSize) {
      toast.error('Please select a valid color and size.');
      return;
    }
  
    const colorDetails = product.product_colors?.find(
      (color) => color.color.name === selectedColor
    );
  
    if (!colorDetails) {
      toast.error('Please select a valid color.');
      return;
    }
  
    const cartItems = JSON.parse(localStorage.getItem('cart')) || [];
    const locationPrice = getLocationBasedPrice(colorDetails);
  
    const isProductInCart = cartItems.some(
      (item) =>
        item.id === product.id && item.color === colorDetails.color.name
    );
  
    if (isProductInCart) {
      toast.error('This product is already in your cart.');
      return;
    }
  
    const cartItem = {
      id: product.id,
      name: product.name,
      slug: product.slug,
      color: colorDetails.color.name,
      size: selectedSize,
      price: locationPrice.amount,
      currency: locationPrice.currency,
      images: colorDetails.images || [],
    };
  
    cartItems.push(cartItem);
    localStorage.setItem('cart', JSON.stringify(cartItems));
  
    setTimeout(() => {
      navigate('/cart');
    }, 500);
  };
  
  
  const getButtonStyle = (size) => ({
    backgroundColor: selectedSize === size ? "#FA8F21" : "transparent",
    color: selectedSize === size ? "#3D291D" : "#3D291D",
  });
  
  const handleAddToCart2 = (productItem, event) => {
    event.stopPropagation();
  
    if (!productItem) return;
  
    const selectedColor = productItem.product_colors[0]?.color.name;
  
    if (!selectedColor) {
      toast.error('No color available for this product.');
      return;
    }
  
    const colorDetails = productItem.product_colors?.find(
      (color) => color.color.name === selectedColor
    );
  
    if (!colorDetails) {
      toast.error('Invalid color for this product.');
      return;
    }
  
    const cartItems = JSON.parse(localStorage.getItem('cart')) || [];
  
    const isProductInCart = cartItems.some(
      (item) =>
        item.id === productItem.id &&
        item.color === colorDetails.color.name &&
        item.size === selectedSize
    );
  
    if (isProductInCart) {
      toast.warning('Product is already in the cart!', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return; // Exit the function if the product is already in the cart
    }
  
  
    const cartItem = {
      id: productItem.id,
      name: productItem.name,
      color: colorDetails.color.name,
      size: selectedSize,
      base_price: colorDetails.base_price,
      images: colorDetails.images || [],
    };
  
    cartItems.push(cartItem);
    localStorage.setItem('cart', JSON.stringify(cartItems));
    
    setTimeout(() => {
      navigate('/cart');
    }, 500);
  };
  

  if (!product) {
    return (
      <div className='flex items-center justify-center min-h-screen bg-[#FDF5F2]'>
        <Spinner />
      </div>
    );
  }

// Extract product details to use in JSX
const { name, description, product_colors, category } = product;

// Get main image and price based on selected color
const selectedColorData = product_colors?.find(color => color.color.name === selectedColor);

const mainImage = selectedColorData?.images?.find(image => image.is_feature)?.image;
const price = selectedColorData?.base_price;

// Get all images for the selected color
const selectedColorImages = selectedColorData?.images || [];

console.log("Selected Color Data:", selectedColorData);

if (!selectedColorData || !selectedColorData.images) {
    console.log("No images found for the selected color.");
} else {
    console.log("Selected Color Images:", selectedColorImages);
}

// Log each image individually
selectedColorImages.forEach((image, index) => {
    console.log(`Image ${index + 1}: URL: ${image.image}, is_feature: ${image.is_feature}`);
});

// Get all non-feature images (is_feature: false)
const nonFeatureImages = selectedColorImages.filter(image => !image.is_feature);
console.log("Non-Feature Images:", nonFeatureImages.length > 0 ? nonFeatureImages : "No non-feature images available");

// Filter out the selected color to get other colors
const otherColors = product_colors.filter(color => color.color.name !== selectedColor);

// Extract images
const featureImage = selectedColorData?.images?.find(image => image.is_feature);
const otherImages = selectedColorData?.images?.filter(image => !image.is_feature) || [];

// Ordered images with the feature image first
const orderedImages = featureImage ? [featureImage, ...otherImages] : otherImages;

  return (
    <div className="flex flex-col items-center min-h-screen bg-[#FDF5F2] px-4 mb-16">
      <Navbar3 />
         <ToastContainer />
    
{otherColors.length === 1 && (
  <div className="flex justify-center md:hidden w-full px-2">
    {/* Red Rectangle - Main Image */}
    <div className="w-64 h-64">
      {mainImage && (
        <img
          src={mainImage}
          alt="Red Main Image"
          className="w-full h-full object-cover"
        />
      )}
    </div>
    
    {/* Blue Rectangle - Alternate Color */}
    <div 
      className="w-64 h-64 cursor-pointer"
      onClick={() => handleNavigate2(`/productDetail/${product.slug}?color=${otherColors[0].color.name}`)}
    >
      {otherColors[0].images.length > 0 && (
        <img
          src={otherColors[0].images[0].image}
          alt={otherColors[0].color.name}
          className="w-full h-full object-cover"
        />
      )}
    </div>
  </div>
)}

<div className="flex items-start mx-4 w-full md:w-auto max-w-md md:max-w-full sm:mt-0 mt-8 lg:mt-16">



<div 
  className={`relative bg-white border border-gray-300 
    ${otherColors.length === 1 ? 'w-64 h-96 md:block hidden' : 'w-64 h-64 sm:w-96 sm:h-96'}`}
>
{orderedImages.length > 0 && (
          <img
            src={orderedImages[currentIndex].image}
            alt={name}
            className="w-full h-full object-cover"
          />
        )}

        {/* Navigation Arrows */}
        {orderedImages.length > 1 && (
          <>
            <button
              className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-gray-800 bg-opacity-50 text-white p-2 rounded-full"
              onClick={handlePrevImage}
            >
              <IoIosArrowBack size={24} />
            </button>
            <button
              className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-gray-800 bg-opacity-50 text-white p-2 rounded-full"
              onClick={handleNextImage}
            >
              <IoIosArrowForward size={24} />
            </button>
          </>
        )}
</div>


{/* Right Column with Conditional Box Size */}
<div className="flex flex-col ml-0">
  {/* Mobile Structure */}
  {otherColors.length > 1 && (
    <div className="block md:hidden">
      {otherColors.slice(0, 2).map((colorOption, index) => (
        <div
          key={index}
          onClick={() => handleNavigate2(`/productDetail/${product.slug}?color=${colorOption.color.name}`)}
          className="bg-white border border-gray-300 flex items-center justify-center cursor-pointer w-32 h-32"
        >
          <div className="flex items-center justify-center w-full h-full">
            {colorOption.images.length > 0 && (
              <img
                src={colorOption.images[0].image}
                alt={colorOption.color.name}
                className="w-full h-full object-cover"
              />
            )}
          </div>
        </div>
      ))}
    </div>
  )}

  {/* Desktop Structure */}
  <div className="hidden md:flex flex-col ml-0">
    {otherColors.length === 1 ? (
      // Single image for desktop when otherColors.length === 1
      <div
        key={0}
        onClick={() => handleNavigate2(`/productDetail/${product.slug}?color=${otherColors[0].color.name}`)}
        className="bg-white border border-gray-300 flex items-center justify-center cursor-pointer w-64 h-96"
      >
        <div className="flex items-center justify-center w-full h-full">
          {otherColors[0].images.length > 0 && (
            <img
              src={otherColors[0].images[0].image}
              alt={otherColors[0].color.name}
              className="w-full h-full object-cover"
            />
          )}
        </div>
      </div>
    ) : (
      // Multiple images for desktop
      otherColors.slice(0, 2).map((colorOption, index) => (
        <div
          key={index}
          onClick={() => handleNavigate2(`/productDetail/${product.slug}?color=${colorOption.color.name}`)}
          className="bg-white border border-gray-300 flex items-center justify-center cursor-pointer w-44 h-48"
        >
          <div className="flex items-center justify-center w-full h-full">
            {colorOption.images.length > 0 && (
              <img
                src={colorOption.images[0].image}
                alt={colorOption.color.name}
                className="w-full h-full object-cover"
              />
            )}
          </div>
        </div>
      ))
    )}
  </div>
</div>


  <div className="hidden sm:block w-[1px] bg-[#3D291D] h-96 mx-4"></div>


        <div className="ml-4 mr-16 hidden md:block">
        <h2 className='font-gothamBold text-[#3D291D]'>
  {selectedColor} {name}
</h2>
<h5 className="font-light font-montserrat text-[#3D291D] mt-2">
  {/* Base price with strike-through */}
  <span className="text-sm line-through">
  {formatCurrency(getLocationBasedPrice(
          product.product_colors?.find(color => color.color.name === selectedColor)
        )?.amount, 
        getLocationBasedPrice(
          product.product_colors?.find(color => color.color.name === selectedColor)
        )?.currency)}
  </span>

  {/* Discounted price */}
  <span className="ml-2 text-[#7DDED9] font-gothamBlack">
  {formatCurrency(
          (parseFloat(getLocationBasedPrice(
            product.product_colors?.find(color => color.color.name === selectedColor)
          )?.amount) * 0.8).toFixed(2),
          getLocationBasedPrice(
            product.product_colors?.find(color => color.color.name === selectedColor)
          )?.currency
        )}
  </span>
</h5>


          <div className="flex space-x-4 mt-4">
      <button
        className="w-10 h-10 flex items-center justify-center border-2 border-[#3D291D] rounded-full font-gothamLight text-sm transition duration-200"
        style={getButtonStyle("S")}
        onClick={() => handleSizeChange("S")}
        onMouseEnter={(e) => {
          if (selectedSize !== "S") e.currentTarget.style.backgroundColor = "#FA8F21";
        }}
        onMouseLeave={(e) => {
          if (selectedSize !== "S") e.currentTarget.style.backgroundColor = "transparent";
        }}
      >
        S
      </button>
      <button
        className="w-10 h-10 flex items-center justify-center border-2 border-[#3D291D] rounded-full font-gothamLight text-sm transition duration-200"
        style={getButtonStyle("M")}
        onClick={() => handleSizeChange("M")}
        onMouseEnter={(e) => {
          if (selectedSize !== "M") e.currentTarget.style.backgroundColor = "#FA8F21";
        }}
        onMouseLeave={(e) => {
          if (selectedSize !== "M") e.currentTarget.style.backgroundColor = "transparent";
        }}
      >
        M
      </button>
      <button
        className="w-10 h-10 flex items-center justify-center border-2 border-[#3D291D] rounded-full font-gothamLight text-sm transition duration-200"
        style={getButtonStyle("L")}
        onClick={() => handleSizeChange("L")}
        onMouseEnter={(e) => {
          if (selectedSize !== "L") e.currentTarget.style.backgroundColor = "#FA8F21";
        }}
        onMouseLeave={(e) => {
          if (selectedSize !== "L") e.currentTarget.style.backgroundColor = "transparent";
        }}
      >
        L
      </button>
    </div>
          <div className="hidden md:block">
          <button
  className="font-gothamLight text-[#3D291D] mt-4 underline cursor-pointer"
  onClick={() => setShowDetails(!showDetails)}
>
  Size Details
</button>
{showDetails && (
  <table className="mt-4 w-full border-collapse border-2 border-[#3D291D]">
    <thead>
      <tr>
        <th className="border-2 border-[#3D291D] px-2 py-1 text-md font-gothamLight text-[#3D291D]">Size</th>
        {sizeDescription && Object.keys(sizeDescription).length > 0 &&
          Object.keys(sizeDescription[Object.keys(sizeDescription)[0]] || {}).map((key) => (
            <th key={key} className="border-2 border-[#3D291D] px-2 py-1 text-md font-gothamLight text-[#3D291D]">
              {key}
            </th>
          ))
        }
      </tr>
    </thead>
    <tbody>
      {sizeDescription && Object.keys(sizeDescription).length > 0 ? (
        Object.keys(sizeDescription).map((size) => (
          sizeDescription[size] && (
            <tr key={size}>
              <td className="border-2 border-[#3D291D] px-2 py-1 text-md font-gothamLight underline text-[#3D291D]">
                {size}
              </td>
              {Object.keys(sizeDescription[size] || {}).map((key) => (
                <td key={key} className="border-2 border-[#3D291D] px-2 py-2 text-xs font-gothamLight text-[#3D291D]">
                  {sizeDescription[size]?.[key]?.cm ?? "N/A"} cm / {sizeDescription[size]?.[key]?.in ?? "N/A"} in
                </td>
              ))}
            </tr>
          )
        ))
      ) : (
        <tr>
          <td colSpan="100%" className="mt-2 text-red-500 text-center">No size details available.</td>
        </tr>
      )}
    </tbody>
  </table>
)}


<div className="flex justify-between w-full mt-4">
  {(() => {
    const selectedColorDetails = product.product_colors?.find(
      color => color.color.name === selectedColor
    );
    
    const isFavorite = favoriteStatus[`${product.id}-${selectedColor}`];
   
    
    return isFavorite ? (
      <FaHeart
        className="text-[#FA8F21] text-3xl cursor-pointer"
        onClick={() => {
          handleActionClick('removeFromWishlist', product, selectedColorDetails);
          // Update local state immediately
          setFavoriteStatus(prev => ({
            ...prev,
            [`${product.id}-${selectedColor}`]: false
          }));
        }}
      />
    ) : (
      <LuHeart
        className="text-[#FA8F21] text-3xl cursor-pointer"
        onClick={() => {
          handleActionClick('addToWishlist', product, selectedColorDetails);
          // Update local state immediately
          setFavoriteStatus(prev => ({
            ...prev,
            [`${product.id}-${selectedColor}`]: true
          }));
        }}
      />
    );
  })()}

  <button
    onClick={handleAddToCart}
    className="bg-[#FA8F21] text-sm font-gothamBold text-white py-2 px-4 rounded-3xl"
  >
    Add to Cart
  </button>
</div>


<div className="w-96 h-px bg-[#3D291D] mt-4" /> {/* Separator */}

<h6 className="font-gothamBold text-[#3D291D] mt-2">Description</h6>
<div className="font-gothamMedium text-xs text-[#3D291D] mt-2 whitespace-pre-line overflow-wrap break-word w-96">
  {description}
</div>

          </div>
        </div>
      </div>

    {/* Mobile View */}
<div className="mt-4 md:hidden text-left w-full px-4">
  <h2 className="font-gothamBold text-[#3D291D]">{selectedColor} {name}</h2>
  <h5 className="font-light font-montserrat text-[#3D291D] mt-2">
  {/* Base price with strike-through */}
  <span className="text-sm line-through">
  {formatCurrency(getLocationBasedPrice(
          product.product_colors?.find(color => color.color.name === selectedColor)
        )?.amount, 
        getLocationBasedPrice(
          product.product_colors?.find(color => color.color.name === selectedColor)
        )?.currency)}
  </span>

  {/* Discounted price */}
  <span className="ml-2 text-[#7DDED9] font-gothamBlack">
  {formatCurrency(
          (parseFloat(getLocationBasedPrice(
            product.product_colors?.find(color => color.color.name === selectedColor)
          )?.amount) * 0.8).toFixed(2),
          getLocationBasedPrice(
            product.product_colors?.find(color => color.color.name === selectedColor)
          )?.currency
        )}
  </span>
</h5>


  {/* Size Buttons */}
  <div className="flex space-x-4 mt-4">
    {['S', 'M', 'L'].map((size) => (
      <button
        key={size}
        className="w-10 h-10 flex items-center justify-center border-2 border-[#3D291D] text-[#3D291D] rounded-full bg-transparent font-gothamLight text-sm transition duration-200 hover:!bg-[#FA8F21]"
      >
        {size}
      </button>
    ))}
  </div>

  <button
  className="font-gothamLight text-[#3D291D] mt-4 underline cursor-pointer"
  onClick={() => setShowDetails(!showDetails)}
>
  Size Details
</button>
{showDetails && (
  <div className="mt-4 overflow-x-auto">
    <table className="min-w-full border-collapse border border-[#3D291D]">
      <thead>
        <tr>
          <th className="border-2 border-[#3D291D] px-2 py-1 text-sm font-gothamBold text-[#3D291D]">
            Size
          </th>
          {sizeDescription && Object.keys(sizeDescription).length > 0 && 
            Object.keys(sizeDescription[Object.keys(sizeDescription)[0]] || {}).map((key) => (
              <th key={key} className="border-2 border-[#3D291D] px-2 py-1 text-sm font-gothamBold text-[#3D291D]">
                {key}
              </th>
            ))
          }
        </tr>
      </thead>
      <tbody>
        {sizeDescription && Object.keys(sizeDescription).length > 0 ? (
          Object.keys(sizeDescription).map((size) => (
            <tr key={size}>
              <td className="border-2 border-[#3D291D] px-2 py-1 text-xs font-gothamLight text-[#3D291D]">
                {size}
              </td>
              {Object.keys(sizeDescription[size] || {}).map((key) => (
                <td key={key} className="border-2 border-[#3D291D] px-2 py-1 text-xs font-gothamLight text-[#3D291D]">
                  {sizeDescription[size]?.[key]?.cm ?? "N/A"} cm / {sizeDescription[size]?.[key]?.in ?? "N/A"} in
                </td>
              ))}
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="100%" className="text-red-500 text-center py-2">
              No size details available.
            </td>
          </tr>
        )}
      </tbody>
    </table>






      {/* Back Button */}
   <div className="flex items-center space-x-0">
  <MdKeyboardArrowLeft className="text-[#3D291D] mt-3 text-2xl" />
  <h6
    className="font-gothamLight text-[#3D291D] mt-4 cursor-pointer"
    onClick={handleBackClick}
  >
    Back
  </h6>
</div>

    </div>
  )}

  {/* Fallback for Missing Data */}
  {showSizeDescription && !sizeDescription && (
    <p className="mt-2 text-red-500">No size details available.</p>
  )}

  {/* Only show these elements when size table is hidden */}
  {!showSizeDescription && (
    <div>
      <div className="flex justify-between w-full mt-4">
 {(() => {
  const selectedColorDetails = product.product_colors?.find(
    (color) => color.color.name === selectedColor
  );

  if (!selectedColorDetails) {
    console.warn('No details found for the selected color.');
    return null;
  }

  const isFavorite = favoriteStatus[`${product.id}-${selectedColor}`];



  return isFavorite ? (
    <FaHeart
      className="text-[#FA8F21] text-3xl cursor-pointer"
      onClick={() => {
        handleActionClick('removeFromWishlist', product, selectedColorDetails);

        // Update local state immediately
        setFavoriteStatus((prev) => ({
          ...prev,
          [`${product.id}-${selectedColor}`]: false,
        }));
      }}
    />
  ) : (
    <LuHeart
      className="text-[#FA8F21] text-3xl cursor-pointer"
      onClick={() => {
        handleActionClick('addToWishlist', product, selectedColorDetails);

        // Update local state immediately
        setFavoriteStatus((prev) => ({
          ...prev,
          [`${product.id}-${selectedColor}`]: true,
        }));
      }}
    />
  );
})()}



        <button
          onClick={handleAddToCart}
          className="bg-[#FA8F21] font-gothamBold text-white py-2 px-4 rounded-3xl"
        >
          Add to Cart
        </button>
      </div>

      <div className="w-full h-px bg-[#3D291D] mt-4" /> {/* Separator */}

      <h6 className="font-gothamBold text-[#3D291D] mt-2">Description</h6>
      <p className="font-gothamMedium text-xs text-[#3D291D] mt-2 whitespace-pre-line">
        {description}
      </p>
    </div>
  )}
</div>


{/* "You might be interested in" Section Header */}
<div className="mt-8 text-center">
  <h3 className="font-gothamBold text-[#3D291D] text-2xl">You might be interested in</h3>
</div>

{/* Responsive Product Grid */}
<div className="mt-4 mb-16">
  {/* Mobile Grid (2 columns) */}
  <div className="grid grid-cols-2 gap-0 md:hidden">
  {products
    .filter((productItem) => productItem.slug !== product.slug)
    .slice(0, 4)
    .map((productItem) => {
      const defaultColorOption = productItem.product_colors[0];
    const defaultColor = defaultColorOption?.color.name;
    const productKey = `${productItem.id}-${defaultColor}`;
    const isFavorite = defaultColorOption?.is_favorite;  // Use is_favorite directly from the API

      return (
        <div
          key={productItem.id}
          onClick={() =>
            handleNavigate(`/productDetail/${productItem.slug}?color=${defaultColor}`)
          }
          className="w-full h-full flex flex-col p-2 no-underline cursor-pointer"
        >
          {/* Image Container */}
          <div className="w-full relative" style={{ paddingBottom: '100%' }}>
            {productItem.product_colors[0]?.images[0]?.image ? (
              <img
                src={productItem.product_colors[0].images[0].image}
                alt={productItem.name}
                className="absolute top-0 left-0 w-full h-full object-cover"
              />
            ) : (
              <div className="absolute top-0 left-0 w-full h-full bg-gray-600" />
            )}
          </div>

          {/* Product Details */}
          <h4 className="font-gothamBold text-[#3D291D] text-base mt-2">
            {`${defaultColor} ${productItem.name}`}
          </h4>

          <p className="font-light font-montserrat text-[#3D291D] text-sm mt-1">
            <span className="text-sm line-through">
              {formatCurrency(
                getLocationBasedPrice(productItem.product_colors[0])?.amount,
                getLocationBasedPrice(productItem.product_colors[0])?.currency
              )}
            </span>
            <span className="ml-2 text-[#7DDED9] font-gothamBlack">
              {formatCurrency(
                (parseFloat(getLocationBasedPrice(productItem.product_colors[0])?.amount) * 0.8).toFixed(2),
                getLocationBasedPrice(productItem.product_colors[0])?.currency
              )}
            </span>
          </p>

          {/* Action Buttons */}
          <div className="flex justify-between items-center mt-auto">
            <button
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                handleAddToCart2(productItem, event);
              }}
              className="bg-[#FA8F21] text-sm font-bold font-gothamBold text-white py-2 px-3 rounded-3xl"
            >
              Add to Cart
            </button>
            {isFavorite ? (
              <FaHeart
                className="text-[#FA8F21] text-3xl cursor-pointer"
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  handleActionClick('removeFromWishlist', productItem, productItem.product_colors[0]);
                  setFavoriteStatus((prev) => ({
                    ...prev,
                    [productKey]: false,
                  }));
                }}
              />
            ) : (
              <LuHeart
                className="text-[#FA8F21] text-3xl cursor-pointer"
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  handleActionClick('addToWishlist', productItem, productItem.product_colors[0]);
                  setFavoriteStatus((prev) => ({
                    ...prev,
                    [productKey]: true,
                  }));
                }}
              />
            )}
          </div>
        </div>
      );
    })}
</div>


  {/* Desktop Flex Layout */}
  <div className="hidden md:flex flex-wrap justify-center gap-3">

{products
  .filter((productItem) => productItem.slug !== product.slug)
  .slice(0, 4)
  .map((productItem) => {
    const defaultColorOption = productItem.product_colors[0];
    const defaultColor = defaultColorOption?.color.name;
    const productKey = `${productItem.id}-${defaultColor}`;
    const isFavorite = defaultColorOption?.is_favorite;  // Use is_favorite directly from the API

 

    return (
      <div
        key={productItem.id}
        onClick={() =>
          handleNavigate(`/productDetail/${productItem.slug}?color=${defaultColor}`)
        }
        className="w-60 h-60 flex flex-col p-4 cursor-pointer mb-4"
      >
        {/* Image Container */}
        <div className="w-full relative" style={{ paddingBottom: '100%', overflow: 'hidden' }}>
          {defaultColorOption?.images[0]?.image && (
            <img
              src={defaultColorOption.images[0].image}
              alt={productItem.name}
              className="absolute top-0 left-0 w-full h-full object-cover object-center"
            />
          )}
        </div>

        {/* Product Details */}
        <h4 className="font-gothamBold text-[#3D291D] text-lg self-start mt-2">
          {`${defaultColor} ${productItem.name}`}
        </h4>

        <p className="font-light font-montserrat text-[#3D291D] text-md mt-1 self-start">
          <span className="text-sm line-through">
            {formatCurrency(
              getLocationBasedPrice(defaultColorOption)?.amount,
              getLocationBasedPrice(defaultColorOption)?.currency
            )}
          </span>
          <span className="ml-2 text-[#7DDED9] font-gothamBlack">
            {formatCurrency(
              (parseFloat(getLocationBasedPrice(defaultColorOption)?.amount) * 0.8).toFixed(2),
              getLocationBasedPrice(defaultColorOption)?.currency
            )}
          </span>
        </p>

        {/* Action Buttons */}
        <div className="flex justify-between items-center w-full mt-auto">
          <button
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              handleAddToCart2(productItem, event);
            }}
            className="bg-[#FA8F21] font-bold font-gothamBold text-white py-2 px-4 rounded-3xl"
          >
            Add to Cart
          </button>
          {isFavorite ? (
            <FaHeart
              className="text-[#FA8F21] text-3xl cursor-pointer"
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                handleActionClick('removeFromWishlist', productItem, defaultColorOption);
              }}
            />
          ) : (
            <LuHeart
              className="text-[#FA8F21] text-3xl cursor-pointer"
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                handleActionClick('addToWishlist', productItem, defaultColorOption);
              }}
            />
          )}
        </div>
      </div>
    );
  })}
  </div>
</div>


<LoginSignupModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
<div className="fixed bottom-32 right-4 lg:right-8 z-50">
            <WhatsappContact />
          </div> 
    </div>
  );
};

export default ProductDetail;