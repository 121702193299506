// PrivacyPolicy.js
import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="p-8">
      <h1 className="text-2xl font-gothamBold">Privacy Policy</h1>
      <p className="mt-4 font-gothamLight">
        At SAAR, we value your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, and safeguard your data when you interact with us.
      </p>

      <h2 className="mt-6 text-xl font-gothamBold">1. Information We Collect</h2>
      <p className="font-gothamLight">
        We may collect personal information such as your name, email address, phone number, and shipping details when you make a purchase or sign up for updates.
      </p>

      <h2 className="mt-6 text-xl font-gothamBold">2. How We Use Your Information</h2>
      <ul className="list-inside list-disc font-gothamLight">
        <li>To process and fulfill your orders</li>
        <li>To communicate updates, promotions, and brand news</li>
        <li>To enhance and personalize your shopping experience</li>
        <li>To ensure the security of our website and services</li>
      </ul>

      <h2 className="mt-6 text-xl font-gothamBold">3. Sharing Your Information</h2>
      <p className="font-gothamLight">
        We do not sell or share your personal data with third parties, except as necessary for order fulfillment, payment processing, or legal compliance.
      </p>

      <h2 className="mt-6 text-xl font-gothamBold">4. Security Measures</h2>
      <p className="font-gothamLight">
        We implement security measures to protect your information from unauthorized access and misuse. However, no online transaction is entirely risk-free, so we encourage customers to take precautions.
      </p>

      <h2 className="mt-6 text-xl font-gothamBold">5. Your Rights</h2>
      <p className="font-gothamLight">
        You have the right to request access to, update, or delete your personal data. If you wish to do so, please contact us.
      </p>

      <h2 className="mt-6 text-xl font-gothamBold">Return & Exchange Policy</h2>
      <ul className="list-inside list-disc font-gothamLight">
        <li>Returns: Accepted only if the product is damaged upon arrival. If the customer wishes to return a non-damaged item, they must cover the return shipping costs. The item must be unused, in its original condition, and with all tags attached.</li>
        <li>Exchanges: Allowed, but if the exchange decision was not made immediately after purchase, the customer is responsible for return shipping fees. The product must be unused and in its original condition.</li>
      </ul>

      <p className="mt-6 font-gothamLight">
        By using our services, you agree to this Privacy Policy. We may update it from time to time, so please review it periodically.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
