import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import logo from '../Assets/navLogo.png';
import { LuShoppingCart, LuHeart, LuSearch } from 'react-icons/lu';
import { IoMenu, IoClose } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { LuLogOut } from 'react-icons/lu';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../index.css';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [error, setError] = useState(null);
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [loading, setLoading] = useState(false);
  const searchRef = useRef(null); // Reference for detecting outside clicks
  let debounceTimer;

  const navigate = useNavigate();

  // New token verification function
  const checkTokenValidity = async () => {
    const token = localStorage.getItem('access_token');
    if (!token) {
      setIsLoggedIn(false);
      return;
    }

    try {
      const response = await fetch('https://saarconcept.net/api/token/verify/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      });

      if (!response.ok) {
        await refreshToken();
      } else {
        setIsLoggedIn(true);
      }
    } catch (error) {
      console.error('Token verification failed:', error);
      await refreshToken();
    }
  };

  // New token refresh function
  const refreshToken = async () => {
    const refreshToken = localStorage.getItem('refresh_token');
    if (!refreshToken) {
      handleLogout();
      return;
    }

    try {
      const response = await fetch('https://saarconcept.net/api/token/refresh/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ refresh: refreshToken }),
      });

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem('access_token', data.access);
        setIsLoggedIn(true);
      } else {
        handleLogout();
      }
    } catch (error) {
      console.error('Token refresh failed:', error);
      handleLogout();
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    setIsLoggedIn(false);
    navigate('/');
    toast.success('Logged out successfully');
  };

  // Add token check on mount and interval
  useEffect(() => {
    checkTokenValidity();
    const tokenCheckInterval = setInterval(checkTokenValidity, 5 * 60 * 1000); // Check every 5 minutes
    return () => clearInterval(tokenCheckInterval);
  }, []);

  // Keep all your existing functions and useEffects
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleNavigation = (path) => {
    navigate(path);
  };

  const scrollToFooter = () => {
    const footer = document.getElementById('footer');
    if (footer) {
      footer.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleSearch = async () => {
    if (searchQuery.trim() === "") {
      setSearchResults([]);
      setError(null);
      setLoading(false);
      return;
    }

    if (searchQuery.length < 3) {
      return;
    }

    try {
      setLoading(true);
      setSearchPerformed(true);

      const response = await fetch(
        `https://saarconcept.net/api/search/?format=json&q=${encodeURIComponent(searchQuery)}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch search results");
      }

      const data = await response.json();
      if (data && data.results && Array.isArray(data.results)) {
        const lowerCaseQuery = searchQuery.toLowerCase();
        const filteredResults = data.results.filter(
          (result) =>
            result.name.toLowerCase().includes(lowerCaseQuery) ||
            result.description.toLowerCase().includes(lowerCaseQuery) ||
            result.product_colors.some((color) =>
              color.color.name.toLowerCase().includes(lowerCaseQuery)
            )
        );

        setSearchResults(filteredResults);

        if (filteredResults.length === 0) {
          setError("No results found.");
        } else {
          setError(null);
        }
      }
    } catch (error) {
      console.error("Error fetching search results:", error);
      setError("Error fetching results. Please try again.");
      setSearchResults([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setSearchPerformed(false); // Close dropdown when clicking outside
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      handleSearch();
    }, 300);
    return () => clearTimeout(debounceTimer);
  }, [searchQuery]);
  
  return (
    <nav className={`p-2 w-full navbar-gradient ${isScrolled ? 'bg-[#FDF5F2] shadow-lg' : 'bg-transparent'} transition-shadow duration-300 ease-in-out`}>
      <div className="w-full flex items-center justify-between font-gotham px-4 lg:px-12">
        <div className="mobile-menu">
          {!isLoggedIn && (
            <div className="auth-links">
              <Link to="/signup" className="signup-link">Sign Up</Link>
              <Link to="/login" className="login-link">Log In</Link>
            </div>
          )}
        </div>

        <div className="flex items-center">
          <button className="lg:hidden text-[#FDF5F2] mr-4 relative" onClick={toggleMenu} aria-label="Toggle menu">
            <IoMenu className="w-5 h-5" />
          </button>
          <Link to="/" onClick={() => handleNavigation('/')} className="ml-4 lg:ml-12">
            <img src={logo} alt="Logo" className="h-7 w-auto lg:h-12" />
          </Link>

          {/* Desktop Navigation links */}
          <ul className="hidden lg:flex space-x-6 ml-12 items-center font-gothamLight mt-3">
            <li>
              <Link to="/" onClick={() => handleNavigation('/')} className="text-[#FDF5F2]  no-underline text-sm">About Us</Link>
            </li>
            <span className="text-[#FDF5F2]">|</span>
            <li>
              <Link to="/categories" onClick={() => handleNavigation('/categories')} className="text-[#FDF5F2] no-underline text-sm">Shop</Link>
            </li>
            <span className="text-[#FDF5F2]">|</span>
            <li>
              <button onClick={scrollToFooter} className="text-[#FDF5F2] no-underline text-sm">Contact Us</button>
            </li>
          </ul>

        </div>

        {/* Icons */}
        <div className="flex items-center space-x-3 lg:mr-12">
       
          {isLoggedIn && (
            <>
            <Link to="/favorites">
      <LuHeart className="text-[#FDF5F2] w-5 h-5 cursor-pointer" aria-label="Wishlist" />
    </Link>
              <Link to="/cart">
  <LuShoppingCart 
    className="text-[#FDF5F2] w-5 h-5 cursor-pointer" 
    aria-label="Shopping cart" 
  />
  
</Link>
 <LuLogOut
                className="text-[#FDF5F2] w-5 h-5 cursor-pointer"
                aria-label="Logout"
                onClick={handleLogout}
              />

            </>
          )}
   <div className="relative hidden lg:flex items-center mr-2 "  ref={searchRef}>
  <input
    type="text"
    placeholder="Search..."
    value={searchQuery}
    onChange={(e) => setSearchQuery(e.target.value)}
    className="w-44 bg-transparent border-1 border-[#FDF5F2] rounded-full py-2 pl-10 pr-12 text-[#FDF5F2] placeholder-[#FDF5F2] focus:outline-none focus:ring-2 focus:ring-[#FDF5F2] text-sm"
  />
  <LuSearch
    className="absolute left-3 top-1/2 transform -translate-y-1/2 text-[#FDF5F2] cursor-pointer"
    onClick={handleSearch}
  />
  {searchPerformed && (
    <div className="absolute top-full left-0 w-44 bg-[#FDF5F2] font-gothamLight text-[#3D291D] rounded-lg shadow-lg max-h-64 overflow-auto z-50 mt-2">
      {loading ? (
        <div className="text-center text-[#3D291D] py-2">Loading...</div> // Loading indicator
      ) : searchResults.length > 0 ? (
        <ul className="px-2">
          {searchResults.map((result, index) =>
            result.product_colors.map((productColor, colorIndex) => (
              <li
                key={`${index}-${colorIndex}`}
                className="px-2 py-2 border-b border-gray-300 cursor-pointer"
                onClick={() => {
                  const colorName = encodeURIComponent(productColor.color.name);
                  const navigationUrl = `/productDetail/${result.slug}?color=${colorName}`;
                  navigate(navigationUrl);
                  setSearchResults([]); // Clear dropdown after navigation
                }}
              >
                <div className="flex justify-between items-center">
                  <span className="text-sm">
                    {productColor.color.name} {result.name}
                  </span>
                </div>
              </li>
            ))
          )}
        </ul>
      ) : (
        <div className="text-center">
          {error && (
            <div className="text-center text-red-500">{error}</div>
          )}
        </div>
      )}
    </div>
  )}
</div>


          {!isLoggedIn && (
            <>
              <Link to="/signup" onClick={() => handleNavigation('/signup')} className="signup-link-desktop">Sign Up</Link>
              <Link to="/login" onClick={() => handleNavigation('/login')} className="login-link-desktop">Log In</Link>
            </>
          )}
        </div>
        {/* Desktop Search Bar */}

      </div>

      <div className="lg:hidden flex flex-col items-center mt-2 relative w-full px-4"  ref={searchRef}>
  <div className="relative w-full">
    <input
      type="text"
      placeholder="Search..."
      value={searchQuery}
      onChange={(e) => setSearchQuery(e.target.value)}
      className="w-full bg-transparent border-1 border-[#FDF5F2] rounded-full py-2 pl-10 pr-12 text-[#FDF5F2] placeholder-[#FDF5F2] focus:outline-none focus:ring-2 focus:ring-[#FDF5F2] text-sm"
    />
    <LuSearch
      className="absolute left-3 top-1/2 transform -translate-y-1/2 text-[#FDF5F2]"
      onClick={handleSearch}
    />
  </div>
  {searchQuery && searchPerformed && ( 
    <div className="absolute top-full left-0 right-0 bg-[#FDF5F2] font-gothamLight text-[#3D291D] rounded-lg shadow-lg w-full max-h-64 overflow-auto z-50 mt-2 px-4 py-2">
      {loading ? (
        <div className="text-center text-[#3D291D] py-2">Loading...</div>
      ) : searchResults.length > 0 ? (
        <ul className="max-h-64 overflow-auto">
          {searchResults.map((result, index) =>
            result.product_colors.map((productColor, colorIndex) => (
              <li
                key={`${index}-${colorIndex}`}
                className="px-4 py-2 border-b border-gray-300 cursor-pointer"
                onClick={() => {
                  const colorName = encodeURIComponent(productColor.color.name);
                  const navigationUrl = `/productDetail/${result.slug}?color=${colorName}`;
                  navigate(navigationUrl);
                  setSearchResults([]); // Clear dropdown after navigation
                }}
              >
                <div className="flex justify-between items-center">
                  <span className="font-sm">
                    {productColor.color.name} {result.name}
                  </span>
                </div>
              </li>
            ))
          )}
        </ul>
      ) : (
        <div className="text-center">
          {error && (
            <div className="text-center text-red-500">{error}</div>
          )}
        </div>
      )}
    </div>
  )}
</div>

   
      {isMenuOpen && (
        <div className="absolute top-12 left-4 right-4 z-40 bg-[#FDF5F2] rounded-lg p-4 w-40 shadow-lg">
          <button className="absolute top-2 right-2 text-[#3D291D]" onClick={toggleMenu} aria-label="Close menu">
            <IoClose className="w-5 h-5" />
          </button>
          {/* Ensure items are aligned in a column */}
          <div className="flex flex-col font-gothamLight items-start space-y-4 text-left">
            <Link to="/" onClick={() => handleNavigation('/')} className="text-[#3D291D] text-sm no-underline">About Us</Link>
            <Link to="/categories" onClick={() => handleNavigation('/categories')} className="text-[#3D291D] text-sm no-underline">Categories</Link>
            <button onClick={scrollToFooter} className="text-[#3D291D] text-sm no-underline">Contact Us</button>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
