import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Import axios
import { FaArrowRight, FaInstagram, FaYoutube, FaEnvelope, FaTiktok, FaLinkedin } from 'react-icons/fa';
import { MdAlternateEmail } from "react-icons/md";


export default function Footer() {
  const [phoneNumber, setPhoneNumber] = useState(''); // State to store the phone number input
  const [error, setError] = useState(''); // State to store error message
  const [success, setSuccess] = useState(''); // State to store success message
  const [countryCode, setCountryCode] = useState('+90'); // State to store the selected country code
  const [countryCodes, setCountryCodes] = useState([]); // State to store country codes from API

  const fetchCountryCodes = async () => {
    try {
      const response = await axios.get('https://restcountries.com/v3.1/all');
      const codes = response.data.map(country => ({
        name: country.name.common,
        code: `+${country.idd.root ? country.idd.root.replace(/\D/g, '') : ''}${country.idd.suffixes ? country.idd.suffixes[0] : ''}`,
      }));
      setCountryCodes(codes);
    } catch (err) {
      console.error('Error fetching country codes:', err);
    }
  };

  useEffect(() => {
    fetchCountryCodes();
  }, []);// Error message dictionary mapping backend errors to user-friendly messages
  const errorMessages = {
    'phone_invalid': 'The phone number you entered is invalid.',
    'phone_required': 'Phone number is required.',
    'already_joined': 'You have already joined the Saar community.',
    'server_error': 'There was a problem with the server. Please try again later.'
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Clear previous error or success messages
    setError('');
    setSuccess('');
  
    try {
      // Make a POST request to the API with the phone number
      const response = await axios.post('https://saarconcept.net/api/whitelist/add/', { phone: phoneNumber });
  
      // If successful, display success message
      setSuccess('Thank you for joining the Saar community! You will receive updates soon.');
    } catch (err) {
      // Log the full error response for troubleshooting
      console.error("Error response:", err);
  
      if (err.response) {
        const { data } = err.response;
  
        // Log the backend response data for further inspection
        console.error("Backend response data:", data);
  
        // Check if 'data.errors' contains specific field errors like 'phone'
        if (data.phone) {
          // If phone field contains an array of errors, join them into a single string
          const phoneErrors = Array.isArray(data.phone) ? data.phone.join(', ') : data.phone;
  
          // Set the phone error message
          setError(phoneErrors);
        } else if (data.errors) {
          // In case of multiple field errors, handle them similarly
          const errorMessage = Array.isArray(data.errors) ? data.errors.join(', ') : data.errors;
          setError(errorMessage);
        } else if (data.message) {
          // If there's a general message field
          setError(data.message);
        } else {
          // If no specific errors, display a generic error message
          setError('An unknown error occurred. Please try again.');
        }
      } else {
        // Handle network or other types of errors
        setError('Something went wrong. Please check your internet connection and try again.');
      }
    }
  };
  
  
  const scrollToAboutUs = () => {
    const aboutSection = document.getElementById("about-us");
    aboutSection.scrollIntoView({ behavior: "smooth" });
  };
    const scrollToOutfit = () => {
      const outfitSection = document.getElementById("full-body-outfit");
      outfitSection.scrollIntoView({ behavior: "smooth" });
    };

    const scrollToWaitlist = () => {
      const waitlistSection = document.getElementById("waitlist");
      if (waitlistSection) {
        waitlistSection.scrollIntoView({ behavior: "smooth" });
      } else {
        console.error("Waitlist section not found");
      }
    };

  return (
    <>
     <div className="h-[1px] bg-[#3D291D] mx-4"></div>
  
     <footer id="footer" className="bg-[#FDF5F2] !bg-[#FDF5F2] text-center lg:text-left w-full py-4">
      <div className="container px-4">
        <div className="grid md:grid-cols-2 lg:grid-cols-2">
          {/* First section with the new paragraph */}
        <div className="mb-6 px-3 text-left mx-2 lg:mx-0">
  <h5 className="mb-2.5 text-xl font-gothamBold text-[#3D291D]">
    Join our community
  </h5>
  <p className="text-xs font-gothamLight text-[#3D291D] mb-2">
    Join our Saar community and be the first to know about our latest collections, exclusive deals, and fashion inspirations! By signing up, you’ll receive updates straight to your inbox, ensuring you never miss out on what’s new. Stay connected with us and elevate your wardrobe with the best of Saar!
  </p>

  {/* Phone number Input and Country Code Dropdown */}
  <div className="flex items-center space-x-2">
    {/* Country Code Selector */}
  <select
  className="p-2 border-b border-[#3D291D] bg-transparent text-[#3D291D] focus:outline-none rounded-none w-[130px] mt-[5px]"
  value={countryCode}
  onChange={(e) => setCountryCode(e.target.value)}
>
  {countryCodes
    .sort((a, b) => a.name.localeCompare(b.name)) // Sort country codes alphabetically by name
    .map((country, index) => (
      <option key={index} value={country.code}>
        {country.code} ({country.name})
      </option>
    ))}
</select>


    {/* Phone Number Input */}
    <input
      type="tel"
      placeholder="Phone number"
      value={phoneNumber}
      onChange={(e) => setPhoneNumber(e.target.value)}
      className="w-full p-2 border-b border-[#3D291D] bg-transparent text-[#3D291D] focus:outline-none placeholder-[#3D291D] rounded-none placeholder-[#B0A08C] w-[205px]"
      style={{ borderTopColor: 'transparent', borderRightColor: 'transparent', borderLeftColor: 'transparent' }}
    />
    <span className="text-[#3D291D] ">
      <FaArrowRight onClick={handleSubmit} style={{ cursor: 'pointer' }} />
    </span>
  </div>

      {/* Display Success or Error Message */}
      {success && <p className="text-green-500 mt-2">{success}</p>}
      {error && <p className="text-red-500 mt-2">{error}</p>}
    </div>

          {/* Desktop Menu section */}
          <div className="mb-6 lg:col-start-4 text-left hidden lg:block">
            <h5 className="mb-2.5 text-xl font-gothamBold text-[#3D291D] ml-8">
              Menu
            </h5>
            <ul className="mb-0 list-none text-left mr-8">
  <li>
    <a
      className="text-xs font-gothamLight text-[#3D291D] no-underline"
      href="https://saarconcept.net/"
    >
      About Us
    </a>
  </li>
  <li>
    <a
      className="text-xs font-gothamLight text-[#3D291D] no-underline"
      href="https://saarconcept.net/categories"
    >
      Shop All
    </a>
  </li>
  <li>
  <a className="text-xs font-gothamLight text-[#3D291D] no-underline" href="/privacy-policy">
  Privacy Policy
</a>


  </li>
  <li>
    <a
      className="text-xs font-gothamLight text-[#3D291D] no-underline"
      href="https://saarconcept.net/form"
    >
      Waitlist
    </a>
  </li>
</ul>


          </div>

     
     <div className="lg:block bg-[#3D291D] mx-4 h-36 hidden lg:block" style={{ width: '1px' }} />
      
          <div className="mb-6 lg:col-start-6 text-left hidden lg:block">
            <h5 className="mb-2.5 text-xl font-gothamBold text-[#3D291D] ml-8">
              Get in touch
            </h5>
            <ul className="mb-0 list-none text-left mr-8">
              <li><a className="text-xs font-gothamLight text-[#3D291D] no-underline" href="#">+90 536 288 72 29</a></li>
              <li><a className="text-xs font-gothamLight text-[#3D291D] no-underline" href="mailto:info@saarconcept.net">info@saarconcept.net</a></li>
            </ul>
            <div className="flex justify-center space-x-2 mt-4 ml-4">
              <a href="https://www.instagram.com/saar.concept?igsh=bzdlbDAxbDBxNWJr" className="bg-[#3D291D] p-2 rounded-full text-white hover:text-[#ff9a24] hover:bg-[#3D291D] transition">
                <FaInstagram size={15} />
              </a>
              <a href="mailto:info@saarconcept.net" className="bg-[#3D291D] p-2 rounded-full text-white hover:text-[#ff9a24] hover:bg-[#3D291D] transition">
                <MdAlternateEmail size={15} />
              </a>
              <a href="https://www.tiktok.com/@saar.concept?_t=8qBivDDWgGF&_r=1" className="bg-[#3D291D] p-2 rounded-full text-white hover:text-[#ff9a24] hover:bg-[#3D291D] transition">
                <FaTiktok size={15} />
              </a>
              <a href="https://www.linkedin.com/company/saar-concept/" className="bg-[#3D291D] p-2 rounded-full text-white hover:text-[#ff9a24] hover:bg-[#3D291D] transition">
                <FaLinkedin size={15} />
              </a>
            </div>
          </div>

          {/* Mobile Menu and Icons section */}
          <div className="mb-6 lg:hidden flex justify-between items-start text-left">
            {/* Menu on the left */}
            <div>
              <h5 className="mb-2.5 text-xl ml-6 font-gothamBold text-[#3D291D]">
                Menu
              </h5>
              <ul className="mb-0 list-none pl-6"> {/* Apply padding-left here */}
  <li><a href="https://saarconcept.net/" className="text-xs font-gothamLight text-[#3D291D] no-underline">About Us</a></li>
  <li><a href="https://saarconcept.net/categories" className="text-xs font-gothamLight text-[#3D291D] no-underline">Shop All</a></li>
  <li><a href="/privacy-policy" className="text-xs font-gothamLight text-[#3D291D] no-underline">Privacy Policy</a></li>
  <li><a href="https://saarconcept.net/form" className="text-xs font-gothamLight text-[#3D291D] no-underline">Waitlist</a></li>
</ul>


              <h5 className="mt-2 text-xl ml-6 font-gothamBold text-[#3D291D]">
                Get in touch
              </h5>
              <ul className="mb-0 list-none pl-6"> {/* Apply padding-left here */}
                <li><a className="text-xs font-gothamLight text-[#3D291D] no-underline" href="#">+90 536 288 72 29</a></li>
                <li><a className="text-xs font-gothamLight text-[#3D291D] no-underline" href="mailto:info@saarconcept.net">info@saarconcept.net</a></li>
              </ul>
            </div>
        <div className="w-0.5 bg-[#3D291D] mx-2 h-24 lg:block mt-12" style={{ width: '1px' }} />

            <div className="grid grid-cols-2 gap-2 mr-8 mt-16">
              <a href="https://www.instagram.com/saar.concept?igsh=bzdlbDAxbDBxNWJr" className="bg-[#3D291D] p-2 rounded-full text-white hover:text-[#ff9a24] hover:bg-[#3D291D] transition">
                <FaInstagram size={15} />
              </a>
              <a href="mailto:info@saarconcept.net" className="bg-[#3D291D] p-2 rounded-full text-white hover:text-[#ff9a24] hover:bg-[#3D291D] transition">
                <MdAlternateEmail size={15} />
              </a>
              <a href="https://www.tiktok.com/@saar.concept?_t=8qBivDDWgGF&_r=1" className="bg-[#3D291D] p-2 rounded-full text-white hover:text-[#ff9a24] hover:bg-[#3D291D] transition">
                <FaTiktok size={15} />
              </a>
              <a href="https://www.linkedin.com/company/saar-concept/" className="bg-[#3D291D] p-2 rounded-full text-white hover:text-[#ff9a24] hover:bg-[#3D291D] transition">
                <FaLinkedin size={15} />
              </a>
            </div>
          
          </div>
        </div>
        <p className="text-[#3D291D] font-gothamBold text-sm">
          © {new Date().getFullYear()} SAAR
        </p>
      </div>
    </footer>
    </>
  );
}
