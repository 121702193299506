import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import col1 from '../Assets/col1.webp';
import col2 from '../Assets/col2.webp';
import col3 from '../Assets/col3.webp';
import col4 from '../Assets/col4.webp';
import col5 from '../Assets/col5.webp';
import col6 from '../Assets/col6.webp';
import col7 from '../Assets/col7.webp';

const Collection = () => {
  const navigate = useNavigate();
  const [navigateTrigger, setNavigateTrigger] = useState(false);

  const handleExploreClick = (e) => {
    navigate('/categories');
    e.stopPropagation();
    setTimeout(() => {
      window.location.reload();
    }, 0);
  };

  const handleProductsClick = () => {
    navigate('/products');
    setTimeout(() => {
      window.location.reload();
    }, 0);
  };

  React.useEffect(() => {
    if (navigateTrigger) {
      navigate('/categories', { replace: true });
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setNavigateTrigger(false);
      }, 0);
    }
  }, [navigateTrigger, navigate]);

  return (
    <div className="w-full overflow-x-hidden mt-0 pt-0 cursor-pointer">
      <div className="w-full overflow-x-hidden mb-4 pt-0" onClick={handleProductsClick}>
        <h1 className="font-gothamBold text-[#3D291D] text-center mb-8 lg:mb-12 xl:mb-16 text-2xl md:text-3xl lg:text-4xl xl:text-5xl">
          Our Collection
        </h1>
        <div className="hidden md:grid grid-cols-6 gap-2 lg:gap-3 xl:gap-4">
    {/* First Row */}
    <div className="col-span-3 relative">
      <div className="w-full h-0 pb-[56.25%]">
        <img src={col1} alt="Collection Image 1" className="absolute inset-0 w-full h-full object-cover" />
      </div>
    </div>

    <div className="col-span-1 relative">
      <div className="w-full h-0 pb-[100%]">
        <img src={col2} alt="Collection Image 2" className="absolute inset-0 w-full h-full object-cover" />
      </div>
    </div>

    {/* col3 - Full height */}
    <div className="col-span-2 row-span-2 relative">
      <img src={col3} alt="Collection Image 3" className="w-full h-full object-cover" />
    </div>

    {/* Smaller Squares */}
    <div className="col-span-1 w-full h-0 pb-[100%] relative">
      <img src={col4} alt="Collection Image 4" className="absolute inset-0 w-full h-full object-cover" />
    </div>

    <div className="col-span-1 w-full h-0 pb-[100%] relative">
      <img src={col5} alt="Collection Image 5" className="absolute inset-0 w-full h-full object-cover" />
    </div>

    <div className="col-span-1 w-full h-0 pb-[100%] relative">
      <img src={col6} alt="Collection Image 6" className="absolute inset-0 w-full h-full object-cover" />
    </div>

    <div className="col-span-1 w-full h-0 pb-[100%] relative">
      <img src={col7} alt="Collection Image 7" className="absolute inset-0 w-full h-full object-cover" />
    </div>
  </div>

        {/* Mobile Layout */}
        <div className="block md:hidden mt-4">
          <div className="flex flex-col gap-2">
            {[[col2, col3], [col1, col5], [col6, col7]].map((pair, index) => (
              <div key={index} className="flex flex-row justify-between gap-1">
                {pair.map((img, subIndex) => (
                  <img 
                    key={subIndex} 
                    src={img} 
                    alt={`Collection Image Mobile ${subIndex}`} 
                    className="w-[49%] h-auto object-cover" 
                  />
                ))}
              </div>
            ))}
          </div>
        </div>

        {/* Explore Button */}
        <div className="flex justify-center mt-8 lg:mt-12 xl:mt-16">
          <button
            onClick={handleExploreClick}
            className="text-[#3D291D] font-gothamBold border-3 border-[#3D291D] rounded-full px-4 py-1 md:px-6 md:py-2 lg:px-8 lg:py-3 xl:px-10 xl:py-4 text-xl md:text-2xl lg:text-3xl xl:text-4xl transition duration-300 no-underline decoration-none hover:bg-[#3D291D] hover:text-white"
          >
            Explore
          </button>
        </div>
      </div>
    </div>
  );
};

export default Collection;
